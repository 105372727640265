/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//Google Font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

:root,
:root[mode="md"],
:root[mode="ios"] {
  --ion-font-family: "Poppins", sans-serif;
  font-family: var(--ion-font-family) !important;
}

// Remix Icon
@import "~remixicon/fonts/remixicon.css";

// Swipper
@import "swiper/scss";
@import "swiper/scss/zoom";

/** Button **/
ion-button {
  --box-shadow: unset;
}

.button-outline::part(native) {
  border-width: 0.5px !important;
}

/** Text **/
.ion-text-bold {
  font-weight: bold !important;
}

.ion-text-500 {
  font-weight: 500;
}

.ion-text-ellipsis-0 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ion-text-ellipsis-1 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ion-text-ellipsis-2 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ion-text-ellipsis-3 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ion-text-danger {
  color: var(--ion-color-danger) !important;
}

/** No **/
.ion-no-background {
  background: transparent !important;
  --background: transparent !important;
}

.ion-no-ripple {
  --ripple-color: transparent;
}

.no-data {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 3em;
}

.full-width {
  width: 100% !important;
}

/** Selectable **/
ionic-selectable-modal {
  ion-header {
    ion-toolbar {
      --background: var(--ion-color-primary);
      --color: var(--ion-color-primary-contrast);

      ion-searchbar {
        --box-shadow: unset !important;
        --border-radius: 4px;
      }

      .ios .button .button-clear {
        --color: var(--ion-color-light);
      }
    }
  }

  ion-header::after {
    display: none;
  }

  ion-footer {
    ion-toolbar {
      --background: #ffffff !important;
      --color: var(--ion-color-primary);

      ion-row ion-col ion-button::part(native) {
        background: var(--ion-color-primary) !important;
        color: var(--ion-color-white) !important;
        border-radius: 4px;
      }
    }
  }
}

.ionic-selectable-item {
  ion-label {
    white-space: pre-wrap !important;
    font-size: 14px !important;
  }
}

// Modal NFC
.nfc-modal {
  //   --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --backdrop-opacity: 1;
}

.nfc-modal .ion-page {
  // margin: 16px;
  // position: absolute !important;
  // contain: layout style !important;
  // height: 100% !important;
}

.nfc-modal::part(backdrop) {
  opacity: 0.5 !important;
}

.nfc-modal::part(content) {
  // min-height: 400px;
  // height: auto;
  width: 95%;
  // min-height: 450px;
  height: auto;

  position: absolute;
  bottom: 10px;
}

.nfc-modal ion-toolbar {
  //   --background: rgb(14 116 144);
  //   --color: white;
}

.modal-date::part(content) {
  width: 100%;
  margin: 16px;
  border-radius: 16px;
}

.modal-validasi::part(content) {
  width: 100%;
  margin: 16px;
  border-radius: 8px;
  // height: auto;
  max-height: 90%;
}

.modal-verifikasi::part(content) {
  width: 100%;
  margin: 16px;
  border-radius: 8px;
  // height: auto;
  max-height: 55%;
}

.item-form {
  --border-width: 0;
  --ripple-color: transparent;
  --inner-padding-end: 0 !important;
  --inner-padding-start: 0 !important;
  --padding-start: 0 !important;

  .label-file {
    position: relative !important;
  }

  ion-label {
    max-width: 500px !important;
    font-size: 14px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a !important;
    margin-bottom: 10px !important;
  }

  ion-input,
  ion-textarea {
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    background: #f4f6f9;
    padding: 6px 12px !important;
    border: 1px solid #e6e6e6;
  }

  ion-input {
    --padding-top: 5px !important;
    --padding-bottom: 5px !important;
  }

  ion-select,
  ion-datetime,
  ionic-selectable {
    border-radius: 4px;
    margin-bottom: 0px;
    font-size: 12px;
    background: #f4f6f9;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #e6e6e6;

    .ionic-selectable-inner {
      .ionic-selectable-value {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 30px;
      }

      .ionic-selectable-icon {
        display: none;
      }
    }

    .invalid {
      border: 1px solid var(--ion-color-danger);
    }
  }

  .ionic-selectable-label-stacked {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ion-datetime-button {
    width: 100%;
  }

  ion-datetime-button::part(native) {
    background: #f4f6f9;
    border-radius: 4px;
    width: 100%;
    float: right;
    justify-content: start;
    display: flex;
    --ripple-color: transparent;
    font-size: 12px;
    padding: 10px;
  }

  .input-disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
}

.item-disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.custom-divider {
  border: 1px solid #f4f4f4;
  margin: 0 16px;
}

.custom-icon-left {
  margin: unset !important;
  text-align: left;
  padding: 10px 0;
  align-items: center;
  display: flex;

  i {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 4px;
    // margin-right: 10px;
  }
}

//Modal Component image viewer
.modal-viewer {
  --background: rgba(44, 39, 45, 0.2);

  &::part(content) {
    backdrop-filter: blur(12px);
  }

  .ion-page {
    background: transparent;

    ion-header {
      --background: transparent;

      ion-toolbar {
        --background: transparent;
      }
    }

    ion-content {
      --background: transparent;

      ion-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
      }
    }

    ion-footer {
      --background: transparent;

      ion-toolbar {
        --background: transparent;
      }
    }
  }
}

// Custom badges
.badges-primary {
  background: var(--ion-color-primary);
}

.badges-danger {
  background: var(--ion-color-danger);
}

.badges-warning {
  background: var(--ion-color-warning);
}

.badges-success {
  background: var(--ion-color-success);
}

.badges-primary-outline {
  background: #fff;
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
}

.badges-info-outline {
  background: #fff;
  color: var(--ion-color-info);
  border: 1px solid var(--ion-color-info);
}

.badges-danger-outline {
  background: #fff;
  color: var(--ion-color-danger);
  border: 1px solid var(--ion-color-danger);
}

.badges-warning-outline {
  background: #fff;
  color: var(--ion-color-warning);
  border: 1px solid var(--ion-color-warning);
}

.badges-success-outline {
  background: #fff;
  color: var(--ion-color-success);
  border: 1px solid var(--ion-color-success);
}

//Toast
.toast-light-success {
  --background: #fff;
  --color: #000;

  &::part(icon) {
    color: var(--ion-color-success);
    width: 34px;
    height: 34px;
  }
}

.toast-light-danger {
  --background: #fff;
  --color: #000;

  &::part(icon) {
    color: var(--ion-color-danger);
    width: 34px;
    height: 34px;
  }
}

.mr-5px {
  margin-right: 5px !important;
}

.mr-10px {
  margin-right: 10px !important;
}

.custom-list-divider {
  background: #edf2fa;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #272727;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.text-italic {
  font-style: italic !important;
}

ion-datetime {
  color: black !important;
}

.d-flex {
  display: flex !important;
}

.report-number {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;

  ion-col {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .number {
    font-size: 10px;
  }

  .date {
    font-size: 10px;
  }
}

.sc-ion-label-ios-s > p {
  color: #000 !important;
}

.no-rounded {
  border-radius: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ion-datetime-button-overlay ion-datetime.datetime-grid {
  width: 100%;
}

.content-myaction {
  ion-list-header {
    ion-label {
      font-style: normal;
      font-weight: 700;
      // font-size: 11px;
      line-height: 16px;
      color: #474747;
    }
  }

  ion-img {
    width: 45px;
    height: auto;
    margin: auto;
  }

  .action-name {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #325aa2;
  }

  ion-badge {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 10px;
  }
}

.custom-modal::part(content) {
  height: auto;
}

.action-file {
  --max-width: 100vw !important;
  --width: 100% !important;
}

.ios .modal-default {
  --width: 100%;
  --height: 100%;
}

.filter-modal {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  --height: 100%;
  --width: 100%;
}

.myaction-modal {
  --height: auto;
  --width: 100%;
}

.myaction-modal::part(content) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  --height: auto;
}

.action-modal {
  height: auto;
  --height: auto;
  --width: 100%;
}

.action-modal::part(content) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  --height: auto;
  height: auto !important;
}
